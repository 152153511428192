@import url(https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display+SC);
.App {
  padding: 1em;
}

label {
  display: inline-block;
  width: 100px;
}

header {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h1 {
  font-family: "Playfair Display SC", sans-serif;
  text-align: center;
  font-size: 64px;
}

/* typography */

html {
  font-family: 'helvetica neue', sans-serif;
}

thead th, tfoot th {
  font-family: 'Rock Salt';
}

th {
  letter-spacing: 2px;
  padding: 10px;
}

td {
  letter-spacing: 1px;
  overflow-x:hidden;
  overflow-y:hidden;
  font-size: 12px;
  padding: 10px;
  text-align: center;
}

/* table */

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  overflow:hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content_td p {
  max-width: 100%;
  max-height: 100px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.photo {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}

.tableRow tr:nth-child(odd){
  background-color: #fff0c7;
}

/* Column Size for thead */
.tableHeader th:nth-child(-n+3),
.tableHeader th:nth-child(6) {
  width: 10%;
}

.tableHeader th:nth-child(4),
.tableHeader th:nth-child(5) {
  width: 30%;
}

/* Column size for tbody */
input, textarea {
  width: 90%;
}

.tableRow td:nth-child(-n+3),
.tableRow td:nth-child(6) {
  width: 10%;
}

.tableRow td:nth-child(4),
.tableRow td:nth-child(5) {
  width: 30%;
}

